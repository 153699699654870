import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto",attrs:{"height":"100vh","width":"256"}},[_c(VNavigationDrawer,{staticClass:"deep-purple accent-4",attrs:{"dark":"","permanent":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c(VBtn,{attrs:{"block":""},on:{"click":_vm.logOut}},[_vm._v(" Logout ")])],1)]},proxy:true}])},[_c(VList,_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,attrs:{"link":""}},[_c('router-link',{staticClass:"link_holder",attrs:{"exact":"","to":{name: item.go}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }